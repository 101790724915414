import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AmBuilderService } from '../am-builder.service';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { ThemeDetails, Theme, PAGE_TYPE } from '../../am-builder.model';

import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { FormDataPrepareService } from './services/form-data-prepare.service';
import { NotifierService } from 'angular-notifier';
import { SidebarSectionsV2Component } from './left-sidebar-v2/sidebar-sections-v2/sidebar-sections-v2.component';

@Component({
  selector: 'am-editorv2',
  templateUrl: './editorv2.component.html',
  styleUrls: ['./editorv2.component.scss']
})
export class Editorv2Component implements OnInit {

  @ViewChild(SidebarSectionsV2Component, { static: true }) child: SidebarSectionsV2Component;

  themeId: any = 1;
  theme: Theme;
  originalTheme: Theme;

  isLoading = false;

  themeNotSupported = false;
  isLoaded = false;
  isError = false;

  viewportSize = 'editor-preview-small';
  changeViewportSize: any;

  shopDomain: string;
 newcomponentData:any

  public route: ActivatedRoute = null;
  public amBuilderService: AmBuilderService = null;
  public cookieService: CookieService = null;
  public location: Location = null;
  public pubsub: NgxPubSubService = null;

  pageType = {
    "PRODUCT_PAGE": "templates/product.json",
    "COLLECTION": "templates/collection.json",
    "COLLECTIONS_LIST": "templates/list-collections.json",
    "HOME_PAGE": "templates/index.json",
    "BLOG_LIST_PAGE": "templates/blog.json",
    "BLOG_POST_PAGE": "templates/article.json"
  }

  pages: any = {
    'HOME_PAGE': 'Home',
    'PRODUCT_PAGE': 'Product',
    'COLLECTION': 'Collection',
    'COLLECTIONS_LIST': 'Collection List',
    'BLOG_LIST_PAGE': 'Blog List',
    'BLOG_POST_PAGE': 'Blog Post'
  }
  jsonData: any = [];
  themeSettings: any = {};

  subscription: Subscription;
  saveSubscription : Subscription;
  formFlagSubscription : Subscription;
  analyticsSubscription : Subscription;

  headerConfig: any = {};
  footerConfig: any = {};
  currentConfig: any = {};

  pageName = '';


  menus: any = [
    {
      icon: '../../../assets/amp/icons/BuyButtonButtonLayoutMajor.png',
      activeIcon : '../../../assets/amp/icons/BuyButtonButtonLayoutMajorBlue.png',
      title: 'Sections',
      key: 'sidemenu',

    },
    {
      icon: '../../../assets/amp/icons/PaintBrushMajor.png',
      activeIcon : '../../../assets/amp/icons/PaintBrushMajorBlue.png',
      title: 'Theme Settings',
      key: 'theme-settings'

    },
    {
      icon: '../../../assets/amp/icons/AppsMajor.png',
      activeIcon : '../../../assets/amp/icons/AppsMajorBlue.png',
      title: 'Analytics',
      key: 'analytics'

    },
    {
      icon: '../../../assets/amp/icons/bicode.png',
      activeIcon : '../../../assets/amp/icons/bicodeb.png',
      title: 'Pages',
      key: 'pages'

    },

  ]
  activeMenuIndex = 0;
  analyticsData : any = {};
  cssData : any = {};
  apiSuccess = false;
  pageChangeVal : any;
  version : any;
  apiVersion : any = 'v3';
  isAmpEnabled : any = false;
  constructor(
    route: ActivatedRoute,
    amBuilderService: AmBuilderService,
    cookieService: CookieService,
    location: Location,
    pubsub: NgxPubSubService,
    private authService: AuthService,
    private data_prepare: FormDataPrepareService,
    private notifier: NotifierService,

  ) {
    this.route = route;
    this.amBuilderService = amBuilderService;
    this.cookieService = cookieService;
    this.location = location;
    this.pubsub = pubsub;

    this.theme = amBuilderService.theme;
  }

 async ngOnInit() {
    // localStorage.setItem('dawnVersion','2');

    this.themeId = this.route.snapshot.paramMap.get('id');
    //custome config add
    this.data_prepare.getheaderCustomeComponent().subscribe((res)=>{
      console.log("////////getheaderCustomeComponent",res);

      if(res){
        this.currentConfig['sections'] = res['sections']
      }

    })
   await this.getThemeVersion(this.themeId);

    let type = this.route.snapshot.queryParams['type'];
    this.shopDomain = this.route.snapshot.queryParams['shop'];
    let planName = this.route.snapshot.queryParams['plan'];
    const token = this.route.snapshot.queryParams['t'];

    this.getFormFlag();
    // Get theme id
    this.callJsonAPI();

    const user = this.authService.getUser();
    this.shopDomain = user.shopDomain;
    planName = user.planName;

    this.amBuilderService.builderConfig.currentPage = PAGE_TYPE.PRODUCT_PAGE;

    // Set these values in service
    this.amBuilderService.shopDetails = {
      domain: this.shopDomain,
      planName: planName
    };
    this.isLoaded = true;

    // Event for changing the preview size
    this.changeViewportSize = this.pubsub.subscribe('VIEWPORT_CHANGE', (newValue: string) => {
      this.viewportSize = 'editor-preview-' + newValue.toLowerCase();
    });
    this.getThemeDetails();
    this.getAnalytics();
    this.getCssSettings();
    if(!sessionStorage.getItem('handles')){
      this.setPreviewPageHandles();
    }
  }

  getThemeVersion(themeId) {

    return new Promise((resolve) => {

      this.amBuilderService.getThemeVersion(themeId)
        .subscribe((response) => {

          console.log(response);
          let tmp = JSON.parse(localStorage.getItem('ampify-user'));

          if(response['is_theme_2_0'] == 3){

            localStorage.setItem('dawnVersion','3');
            this.version = '3'

            resolve('success');
          } else if(tmp.shopDomain == 'ef420.myshopify.com'){

              localStorage.setItem('dawnVersion','ef420');
              this.version = '2';
              resolve('success');
          } else {

            this.version = '2'
            localStorage.setItem('dawnVersion','2');
            resolve('success');
          }
        })
      })

    }

  async callJsonAPI() {

    this.apiVersion = await this.getVersionOfTheme();

    this.getSaveData();
    await this.getHeaderFooterConfig('config/settings_data.json',this.apiVersion);
    this.getAnalyticsObj();
    this.getCssObj();
  }

  getThemeDetails() {
    // Load theme content
    this.amBuilderService.loadThemeDetails(this.themeId)
      .subscribe((res) => {
        const shopDetails = res[0];
        const themeDetails = res[1];

        // console.log("getThemeDetails",res)
        // TODO: Figure out better place for this
        this.amBuilderService.editorDetails = shopDetails;

        this.theme.id = themeDetails.id;
        this.theme.type = themeDetails.theme_type;
        // this.theme.is_theme_2_0 = themeDetails['is_theme_2_0']

        if (!this.amBuilderService.isV2ThemeSupported(this.theme,res[1]['is_theme_2_0'])) {
          this.isLoaded = true;
          this.themeNotSupported = true;
          return;
        }
      })
  }

 async pageChange($event) {

    this.activeMenuIndex = 0;
    // console.log("pageChange", $event,this.amBuilderService.builderConfig.basePage);
    this.pageChangeVal = $event;
    let path;
    path = $event

    this.apiVersion = await this.getVersionOfTheme();

    this.amBuilderService.getV2ThemeConfigurationForTemplate(this.themeId,this.amBuilderService.builderConfig.basePage,path,this.apiVersion)
      .subscribe((response) => {

        // //console.log("pageChange", response);
        this.themeSettings = response[0];

        this.jsonData = this.themeSettings.json_data;
        this.data_prepare.setSectionData(this.jsonData);
        localStorage.setItem("uData",JSON.stringify(this.jsonData));
        // this.pubsub.publishEvent('RE_RENDER_PAGE', $event);
        this.pubsub.publishEvent('PAGE_TYPE_CHANGE', $event);
        // this.publistPreviewEvent();
        this.isError = false;
        this.isLoaded = true;
      }, (err) => {
        // //console.log(err);
        this.isError = true;
        this.isLoaded = true;
      });
  }

  getHeaderFooterConfig($event,apiVersion) {

    // //console.log("pageChange", $event);

    return new Promise((resolve) => {

      this.amBuilderService.getV2ThemeConfigurationForTemplate(this.themeId, 'settings_data', $event,apiVersion)
        .subscribe((response) => {
          console.log("/////editor v2.........",response[0].json_data.current.sections);

          // this.themeSettings = response[0];
          // this.jsonData = this.themeSettings.json_data
          this.currentConfig = response[0].json_data.current;
          this.amBuilderService.theme.global_settings = this.currentConfig;
          let array = [];
          if(this.version == 3 || this.version == '3') {
           array = this.currentConfig['order'];
          } else {
            array = ['announcement-bar','header']
          }

          for(let i=0; i < array.length; i++){

            console.log("array[i]",array[i])
            if(array[i] != 'footer'){

              this.headerConfig[array[i]] = response[0].json_data.current.sections[array[i]];
              this.currentConfig['sections'][array[i]] = response[0].json_data.current.sections[array[i]];
            }
          }

          // this.headerConfig['custom_section'] = response[0].json_data.current.sections['custom_section'];
          // this.headerConfig['announcement-bar'] = response[0].json_data.current.sections['announcement-bar'];
          // this.headerConfig['header'] = response[0].json_data.current.sections['header'];
          this.footerConfig['footer'] = response[0].json_data.current.sections['footer'];

          console.log("pageChange>>>>>>>>>>>>>>>>>>>>", this.currentConfig);

          this.data_prepare.setThemeSettings(this.currentConfig);
          resolve(null)

          this.isError = false;
           setTimeout(() => {
            this.isLoaded = true;
           }, 400);
        }, (err) => {
          // //console.log("header",err)
          this.isError = true;
          this.isLoaded = true;
          resolve(null)

        });
    })
  }

  getSaveData() {

    this.saveSubscription = this.data_prepare.getSaveData().subscribe(res => {

      console.log("getSaveData....................", res);
      this.themeSettings.json_data = res;
      // this.putThemeData();
    })
  };

 async putThemeData() {

    //   let tmp = {
    //     'header' : this.headerConfig['header'],
    //     'announcement-bar' : this.headerConfig['announcement-bar'],
    //     'footer' : this.footerConfig['footer']

    // }
    // this.currentConfig['sections'] = tmp;

    // this.themeSettings.json_data = {
    //         current : this.currentConfig,
    //         order : this.themeSettings.json_data['order'],
    //         sections : this.themeSettings.json_data['sections']
    //     }
    this.apiVersion = await this.getVersionOfTheme();

    this.setPageType(this.amBuilderService.builderConfig.basePage)

    this.apiSuccess = true;

    // console.log("this.amBuilderService.builderConfig",this.amBuilderService.builderConfig)

      this.themeSettings['templateType'] = this.amBuilderService.builderConfig.currentPage;
      this.themeSettings['type'] = this.amBuilderService.builderConfig.basePage;


    // this.themeSettings['pageType'] = this.amBuilderService.builderConfig.currentPage;
    this.amBuilderService.updateDawnTheme(this.themeId, this.themeSettings._id, this.themeSettings,this.apiVersion)
      .subscribe((response) => {

        // console.log("updateDawnTheme>>>>>>>>>v.,.,", response);
        this.notifier.notify('default', 'Theme configuration updated.');
        this.data_prepare.setFieldEditedEvent(false);
        this.putSettingData();
        this.pageChange(this.amBuilderService.builderConfig.currentPage);
        this.data_prepare.setFormFlag(false);
        // this.getHeaderFooterConfig('config/settings_data.json');
        this.data_prepare.setSectionData(this.themeSettings.json_data);
        this.apiSuccess = false;
        // this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', {});


      }, (err) => {

      });

  }

  refreshData($event) {


    this.isLoading = true;

    this.amBuilderService.getV2LatestThemeConfiguration(this.themeId)
      .subscribe(async (response) => {

        let tmp = JSON.parse(localStorage.getItem('ampify-user'));
        if(tmp.shopDomain == 'ef420.myshopify.com'){

          localStorage.setItem('dawnVersion','ef420');
          this.apiVersion = 'v3'
          this.data_prepare.setThemeVersion('ef420');
        } else {

          localStorage.setItem('dawnVersion','3');
          this.apiVersion = 'v4';
        this.addColorSchemes();

          this.data_prepare.setThemeVersion('3');

        }
        this.callJsonAPI();


        // //console.log("data refresh");
        this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', {});
        // this.pageChange($event);
      this.setPreviewPageHandles();



        // await this.getHeaderFooterConfig('config/settings_data.json');

        this.isLoading = false;
      }, err => {

        this.notifier.notify('default', 'We only support import for dawn theme');

        this.callJsonAPI();

        this.isLoading = false;
      })
  }

  getFormFlag() {

    this.formFlagSubscription = this.data_prepare.getSaveFlag().subscribe(res => {

      // //console.log("getFormFlag", res);
      if (res) {

        console.log("this.cssData",this.cssData);
        this.putCssData();
        this.putThemeData();
        this.putAnalytics();
      }

    })
  };

 async putSettingData() {

  this.apiVersion = await this.getVersionOfTheme();

    this.themeSettings['type'] = 'settings_data';
    this.themeSettings['json_data'] = {
      'current': this.currentConfig
    }
    this.themeSettings['pageType'] = this.amBuilderService.builderConfig.currentPage;
    this.themeSettings['template'] = "config/settings_data.json";
    this.themeSettings['templateType'] = "config/settings_data.json";
    console.log("////////newcomponentData",this.newcomponentData);

    if(this.newcomponentData){
      let orderArray = this.newcomponentData.order
      orderArray.forEach((item:any,index:any) => {


        if (item === 'custom_section' ) {

          this.themeSettings['json_data'].current.sections['custom_section'] = this.newcomponentData.sections.custom_section

        }else if(item === `custom_section-${index}`){
         let dyanamicVar = `custom_section-${index}`
          this.themeSettings['json_data'].current.sections[dyanamicVar] = this.newcomponentData.sections[dyanamicVar]
        }
      });

    }
    this.amBuilderService.updateSettingData(this.themeId, this.themeSettings._id, this.themeSettings,this.apiVersion)
      .subscribe((response) => {

        // console.log("putSettingData()>>>>>>>>v....", response);
        // this.notifier.notify('default', 'Theme configuration updated.');
        // this.pubsub.publishEvent('REFRESH_IFRAME_EVENT', {});


      }, (err) => {

      });

  }
  changeMenu(index) {
    this.activeMenuIndex = index;

  }

  getAnalytics() {

    this.amBuilderService.getV2ThemeAnalytics(this.themeId)
      .subscribe((response) => {

        // //console.log("pageChange", response);
        this.data_prepare.setAnalyticsSettings(response);

      })
  }
  putAnalytics() {

    this.amBuilderService.updateV2ThemeAnalytics(this.themeId,this.analyticsData)
      .subscribe((response) => {

        // //console.log("pageChange", response);
        // this.notifier.notify('default', 'Theme configuration updated.');


      })
  }
  putCssData() {

    let obj = {
    "customCSS" : this.cssData,
    "enabled_amp" : this.cssData['enabled_amp']
    }
    if(obj['enabled_amp'] == undefined || obj['enabled_amp'] == null){

      obj['enabled_amp'] = false;
    }

    console.log("is amp checking",this.isAmpEnabled, obj['enabled_amp']);
    if(this.isAmpEnabled != obj['enabled_amp']){

      this.deletePages();
    }


    let keys = Object.keys(this.cssData);
    if(keys.length != 0){
    this.amBuilderService.updateV2ThemeCss(this.themeId,obj)
      .subscribe((response) => {

        // //console.log("pageChange", response);
        // this.notifier.notify('default', 'Theme configuration updated.');
        this.isAmpEnabled = obj['enabled_amp']

      })
    }
  }

  getAnalyticsObj(){

    this.analyticsSubscription = this.data_prepare.getAnalyticsSettings().subscribe(res => {


      // //console.log("getAnalyticsObj",res);
      this.analyticsData = res;
    })

  }
  getCssObj(){

    this.subscription = this.data_prepare.getCssSettings().subscribe(res => {


      // //console.log("cssData",res);
      this.cssData = res['customCSS'];
    })

  }

  publistPreviewEvent() {
    let globSett = {
      current : this.currentConfig
    }
    let obj = {
      globalSettings: globSett,
      sections: this.jsonData.sections,
      order: this.jsonData.order,
      isDev: true,
      page: ''
    }

    // //console.log("conf", obj)
    this.pubsub.publishEvent('CONFIGURATION_CHANGE', obj);
    // //console.log("value changed", this.mainMenu)
  }

  setPreviewPageHandles(){

    this.amBuilderService.getPreviewPageHandles().subscribe( handles =>{

      // //console.log(handles);
      sessionStorage.setItem('handles', JSON.stringify(handles));

    },err =>{
      // //console.log(err)
    })
  }
  getCssSettings() {

    this.amBuilderService.getV2ThemeCssSettings(this.themeId)
      .subscribe((response) => {

        // //console.log("pageChange", response);
        this.data_prepare.setCssSettings(response);
        this.amBuilderService.cssSettings = response;
        this.isAmpEnabled = response['customCSS']['enabled_amp'];

      })
  }

  setPageType(page){

    if(page.indexOf('product') > -1){

      this.amBuilderService.builderConfig.basePage = 'product'
    }
  }

  getVersionOfTheme(){
    return new Promise((resolve) => {

      let tmp = localStorage.getItem('dawnVersion');
      console.log("getVersionOfTheme",tmp, typeof tmp);
      if(tmp == '3'){

        this.addColorSchemes();
        resolve('v4');
      } else {

        resolve('v3');
      }

    })
  }

  addColorSchemes(){

    const objectToCheck = {
      icon: '../../../assets/amp/icons/colorSchemeGray.svg',
      activeIcon : '../../../assets/amp/icons/colorSchemeBlue.svg',
      title: 'Color Schemes',
      key: 'color-schemes'

    };

    const isObjectInArray = this.menus.some(obj => {
      // Compare objects based on their properties
      return obj.key === objectToCheck.key && obj.title === objectToCheck.title;
    });

    if (!isObjectInArray) {

      console.log('The object is not present in the array.');
      this.menus.push( {
        icon: '../../../assets/amp/icons/colorSchemeGray.svg',
        activeIcon : '../../../assets/amp/icons/colorSchemeBlue.svg',
        title: 'Color Schemes',
        key: 'color-schemes'

      })
    }
  }
  deletePages() {

    this.amBuilderService.deletePages(this.themeId)
      .subscribe((response) => {

        console.log("pageChange", response);

      })
  }

  ngOnDestroy() {
    this.changeViewportSize.unsubscribe();
    this.subscription.unsubscribe();
    this.analyticsSubscription.unsubscribe();
    this.saveSubscription.unsubscribe();
    this.formFlagSubscription.unsubscribe();

  }


}
