import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

import { AmBuilderService } from '../../am-builder.service';

import { environment } from '../../../../environments/environment';
import { IFrameMessage, PAGE_TYPE } from '../../../am-builder.model';
import { Subscription } from 'rxjs';
import { FormDataPrepareService } from '../services/form-data-prepare.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'am-builder-preview-v2',
  templateUrl: './builder-preview-v2.component.html',
  styleUrls: ['./builder-preview-v2.component.scss']
})
export class BuilderPreviewV2Component implements OnInit {

  @ViewChild('previewIframe', { static: true }) previewIframe: ElementRef;

  @Input()
  type: string;

  loading: boolean;
  previewUrl: SafeResourceUrl;
  viewportSize = 'small';

  pageHandles = [];
  productHandle = '';
  collectionHandle = '';
  blogHandle = '';
  articleHandle = '';
  pageHandle = '';
  pageUrl = "";
  editingAllowedInCurrentTier: boolean = true;
  neededPlanName: string = 'hobbyist';

  sendIframeEvent: any;
  refreshIframeEvent: any;
  changePageTypeEvent: any;
  changeViewportSizeEvent: any;
  changeFieldEvent: any;
  changeConfigurationEvent: any;

  currentItemHandle: string;

  pageType: string;
  shopDomain: string;

  baseUrl = environment.baseUrl;

  subscription : Subscription;
  scrollSubscription : Subscription;

  jsonData : any = [];
  currentConfig : any;

  constructor(
    private pubsub: NgxPubSubService,
    private amBuilderService: AmBuilderService,
    private sanitizer: DomSanitizer,
    public data_prepare : FormDataPrepareService,
    public activatedRoute : ActivatedRoute
  ) {
    this.getThemeSettingData();

    this.loading = false;
    this.currentItemHandle = '';

    let pageHandles = JSON.parse(sessionStorage.getItem('handles')) || [] ;

    let len = pageHandles.length;
    for (let i = 0; i < len; i+=1) {

      switch (pageHandles[i].type) {
        case 'product':
          this.productHandle = pageHandles[i].handle
          break;
        case 'collection':
          this.collectionHandle = pageHandles[i].handle
          break;
        case 'blog':
          this.blogHandle = pageHandles[i].handle
          break;
        case 'article':
          this.articleHandle = pageHandles[i].handle
          break;
          case 'page':
          this.pageHandle = pageHandles[i].handle
          break;
      }

    }

  }

  ngOnInit() {
    // this.getSaveData();
    this.getSectionData();
    this.getScrollSection();
    this.currentItemHandle = this.amBuilderService.editorDetails.pages.product || 'any';
    this.shopDomain = this.amBuilderService.shopDetails.domain;

    console.log("this.amBuilderService.shopDetails",this.amBuilderService.shopDetails)

    this.pageType = 'collection';
    // this.pageType = PAGE_TYPE.HOME_PAGE;

    this.listenForIframeMessage();

    // Send iframe message event
    this.sendIframeEvent = this.pubsub.subscribe('SEND_IFRAME_EVENT', (messageObj: any) => {
      const message = {
        type: messageObj.type,
        shopDomain: this.shopDomain,
        pageType: this.pageType,
        currentItemHandle: this.currentItemHandle,
        configuration: messageObj
      };
      message.type = messageObj.type;
      message.configuration = messageObj.configuration;
      // ////console.log("SEND_IFRAME_EVENT",message);
      const jsonMessage = JSON.stringify(message);
      this.sendIframeMessage(jsonMessage);
    });

    // Event for refreshing the iframe
    this.refreshIframeEvent = this.pubsub.subscribe('REFRESH_IFRAME_EVENT', (newValue) => {
      this.changePageType(this.pageType);
    });

    // Event for changing the page type
    this.changePageTypeEvent = this.pubsub.subscribe('PAGE_TYPE_CHANGE', (newValue) => {
      // ////console.log(newValue);
      this.changePageType(newValue);
    });

    // Event for changing the preview size
    this.changeViewportSizeEvent = this.pubsub.subscribe('VIEWPORT_CHANGE', (newValue) => {
      this.setViewportSize(newValue);
    });

    // Event for changing of the configuration needed for re-rendering
    this.changeConfigurationEvent = this.pubsub.subscribe('CONFIGURATION_CHANGE', (newConfiguration: any) => {

      // console.log(this.amBuilderService.editorDetails.pages);

      let templateName = this.amBuilderService.builderConfig.currentPage;
      // let tmp = this.amBuilderService.builderConfig.currentPage.split(".");
      // if(tmp && tmp.length > 2){

      //   templateName = tmp[1];

      // }
      // Set content for home in payload
      // ////console.log(this.amBuilderService.theme.sectionContent)
      newConfiguration.sectionContent = this.amBuilderService.theme.sectionContent;

      const message: any = {
        type: 'RE_RENDER_PAGE',
        shopDomain: this.shopDomain,
        pageType: this.amBuilderService.builderConfig.basePage,
        currentItemHandle: this.currentItemHandle,
        configuration: newConfiguration,
        template : templateName
      };

      this.loading = true;

      ////console.log("this is change we detect")

      // Make ajax call to get new html
      this.amBuilderService.reRenderPageV2(message)
        .subscribe(res => {
          // Send the new html to the iframe
          ////console.log(this.pageUrl,"##########################")
          this.setIframeUrl(this.pageUrl);
          // message.configuration = res;
          // this.updateIframeContent(message);

          this.loading = false;
        }, err => {
          ////console.log(err);
          this.loading = false;
        });
    });

    // Event for changing of the configuration needed for re-rendering
    this.changeFieldEvent = this.pubsub.subscribe('FIELD_CHANGE', (value: any) => {
      const message: IFrameMessage = {
        type: 'UPDATE_FILED',
        shopDomain: this.shopDomain,
        pageType: this.pageType,
        currentItemHandle: this.currentItemHandle,
        configuration: value
      };

      this.updateIframeContent(message);
    });

    let proxy = '/a/amp';
    if(this.shopDomain == 'regency-chess-sets.myshopify.com'){
      proxy = '/apps/fireamp'
    }
    // Initial value for iframe
    let url = '';
    url = `${this.baseUrl}/ampify/${this.shopDomain}/products/${this.productHandle}?dev=true`;

    // url = `https://ampify-me-website.myshopify.com/a/amp`;
    // url += '?dev=true';
    // url += '&theme=' + this.activatedRoute.snapshot.paramMap.get('id');

    this.setIframeUrl(url);
  }

  ngOnDestroy() {
    this.sendIframeEvent.unsubscribe();
    this.refreshIframeEvent.unsubscribe();
    this.changePageTypeEvent.unsubscribe();
    this.changeViewportSizeEvent.unsubscribe();
    this.changeConfigurationEvent.unsubscribe();
    this.changeFieldEvent.unsubscribe();
  }

  setIframeUrl(url: string): void {
    this.previewUrl = this.trustSrcUrl(url);
    console.log("previewUrl",this.previewUrl)
    // this.scrollTo()
  }

  trustSrcUrl(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  changePageType(pageType: string): void {

    ////console.log("on every change@@@@@@@@",pageType);

    this.pageType = pageType;

    // Have default pages in here? Default product, default collection, default blog post
    // default blog list, default page?

    const planName = this.amBuilderService.shopDetails.planName;
    this.editingAllowedInCurrentTier = true;

    this.neededPlanName = 'hobbyist';

    // console.log(this.amBuilderService);
    let templateName = '';
    let templateType = '';
    let templ = this.amBuilderService.builderConfig.currentPage.split(".");
    let templType;

    console.log(templ);
    if(templ.length > 2) {

      templateName = templ[1];

      templType = templ[0].split("/");
      console.log(templType);
      templateType = templType[1];

    }

    console.log(templateName,templateType);

    let userDetails = JSON.parse(localStorage.getItem('ampify-user'));


    let proxy = '/' + userDetails.proxy;
    // if(this.shopDomain == 'regency-chess-sets.myshopify.com'){
    //   proxy = '/apps/fireamp'
    // }

    switch (pageType) {
      case 'templates/index.json':
        this.currentItemHandle = '';
        this.pageUrl = `https://${this.shopDomain}${proxy}?dev=true`;
        if (planName !== 'company' && planName !== 'enterprise') {
          this.editingAllowedInCurrentTier = false;
          this.neededPlanName = 'company';
        }
        break;
      case 'templates/product.json':
        this.currentItemHandle = this.amBuilderService.editorDetails.pages.product || 'any';
        this.pageUrl = `${this.baseUrl}/ampify/${this.shopDomain}/products/${this.productHandle}?dev=true`;
        break;
      case 'templates/collection.json':
        console.log("in collection")
        this.currentItemHandle = this.amBuilderService.editorDetails.pages.collection || 'all';
        // url = `${this.baseUrl}/a/amp/${this.shopDomain}/collections/${this.currentItemHandle}`;
        this.pageUrl = `${this.baseUrl}/ampify/${this.shopDomain}/collections/${this.collectionHandle}?dev=true`
        if (planName !== 'company' && planName !== 'enterprise' && planName !== 'hobbyist') {
          this.editingAllowedInCurrentTier = false;
        }
        break;
      case 'templates/list-collections.json':
        this.currentItemHandle = '';
        // url = `${this.baseUrl}/a/amp/${this.shopDomain}/collections`;
        this.pageUrl = `${this.baseUrl}/ampify/${this.shopDomain}/collections?dev=true`
        if (planName !== 'company' && planName !== 'enterprise' && planName !== 'hobbyist') {
          this.editingAllowedInCurrentTier = false;
        }
        break;
      case 'templates/blog.json':
        this.currentItemHandle = this.amBuilderService.editorDetails.pages.blog || 'news';
        // url = `${this.baseUrl}/a/amp/${this.shopDomain}/blogs/${this.currentItemHandle}`;
        this.pageUrl = `${this.baseUrl}/ampify/${this.shopDomain}/blogs/${this.blogHandle}?dev=true`
        if (planName !== 'company' && planName !== 'enterprise') {
          this.editingAllowedInCurrentTier = false;
          this.neededPlanName = 'company';
        }
        break;
      case 'templates/article.json':
        this.currentItemHandle = (this.amBuilderService.editorDetails.pages.blog || 'news') + '/' + (this.amBuilderService.editorDetails.pages.blogPost || 'test');
        // url = `${this.baseUrl}/a/amp/${this.shopDomain}/blogs/${this.currentItemHandle}`;
        this.pageUrl = `${this.baseUrl}/ampify/${this.shopDomain}/blogs/${this.blogHandle}/${this.articleHandle}?dev=true`
        if (planName !== 'company' && planName !== 'enterprise') {
          this.editingAllowedInCurrentTier = false;
          this.neededPlanName = 'company';
        }
        break;
        case 'templates/page.json':
        this.currentItemHandle = this.amBuilderService.editorDetails.pages.page || 'any';
        this.pageUrl = `${this.baseUrl}/ampify/${this.shopDomain}/pages/${this.pageHandle}?dev=true`;
        break;
      default:
        this.editingAllowedInCurrentTier = false;
        this.neededPlanName = 'company';
        if(templateType == 'product'){
          this.currentItemHandle = this.amBuilderService.editorDetails.pages.product || 'any';
        } else if(templateType == 'collection'){

          this.currentItemHandle = this.amBuilderService.editorDetails.pages.collection || 'all'
        } else if(templateType == 'blog'){

          this.currentItemHandle = this.amBuilderService.editorDetails.pages.blog || 'news'
        } else if(templateType == 'article'){

          templateType = 'blog'
          this.currentItemHandle = this.amBuilderService.editorDetails.pages.blogPost || 'news' + '/' + (this.amBuilderService.editorDetails.pages.blogPost || 'test')
        }
        // this.currentItemHandle = this.amBuilderService.editorDetails.pages.product || 'any';
        this.pageUrl = `${this.baseUrl}/ampify/${this.shopDomain}/${templateType}s/${this.currentItemHandle}?dev=true`;
        break;
    }


    // url += '?dev=true';
    // this.pageUrl = "https://ap-amp.ap.ngrok.io/ampify?shop=lovulovu.myshopify.com&dev=true"
    this.pageUrl += '&theme=' + this.activatedRoute.snapshot.paramMap.get('id');
    this.pageUrl += '&template=' + templateName


    // console.log(this.pageUrl);

    // url += '&ref=editor';

    // TODO: Do the post of the re-render iframe, to show the changes from previous screen
    let tmp = localStorage.getItem("uData");
    let secti = JSON.parse(tmp);
    let globSett = {
      current : this.currentConfig
    }
    const conf = {
      globalSettings: globSett,
      sections: secti['sections'],
      order : secti['order'],
      isDev : true
    };
    ////console.log("conf",conf,this.currentItemHandle);

    let id = document.getElementById('9fde7253-229e-444a-bcc1-4168f46afd1a');
    ////console.log("9fde7253-229e-444a-bcc1-4168f46afd1a",id);

    this.pubsub.publishEvent('CONFIGURATION_CHANGE', conf);
  }

  upgrade(): void {
    const upgradeUrl = `${environment.baseUrl}/install/upgrade?shop=${this.shopDomain}&tier=${this.neededPlanName}`;
    window.location.href = upgradeUrl;
  }

  listenForIframeMessage(): void {
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessage.bind(this), false);
    } else {
      (<any>window).attachEvent('onmessage', this.receiveMessage.bind(this));
    }
  }
  receiveMessage(message) {
    let data = message.data;
    try {
      data = JSON.parse(data);
    } catch (e) { }

    if (data.type === 'IFRAME_CONTENT_LOADING') {
      // Set loader to true/false
      this.loading = data.value;
    }
  }

  updateIframeContent(message: IFrameMessage): void {
    const jsonMessage = JSON.stringify(message);
    this.sendIframeMessage(jsonMessage);
  }

  sendIframeMessage(message) {
    const window = this.previewIframe.nativeElement.contentWindow;
    ////console.log("**sendIframeMessage**",message,window);
    window.postMessage(message, '*');
  }

  setViewportSize(size: string) {
    ////console.log("viewportSize",size)
    this.viewportSize = size.toLowerCase();
  }

  scrollTo(){
    setTimeout(() => {
      const window = this.previewIframe.nativeElement.contentWindow;
      window.postMessage(window.scrollTo(0, 500), '*');
    },10000);

  }
  getSectionData() {

    this.subscription = this.data_prepare.getSectionData().subscribe(res => {

      ////console.log("$$$$getSectionData$$$$", res);
      this.jsonData = res;
      // this.mainMenu = res;
      // this.stateArray[0]['data'] = this.mainMenu;
    })
  }
  getThemeSettingData() {

    this.subscription = this.data_prepare.getThemeSettings().subscribe(res => {

      ////console.log("getThemeSettingData", res);
      this.currentConfig = res;

    })
  };

  getScrollSection(){

    this.scrollSubscription = this.data_prepare.getScrollSection().subscribe(res => {


      // document.getElementById(res).scrollIntoView();
      // var iframe = document.getElementById('iframePreview');
      ////console.log("getAnalyticsObj",document.getElementById('9fde7253-229e-444a-bcc1-4168f46afd1a'));

      const window = this.previewIframe.nativeElement.contentWindow;
      window.postMessage(window.scrollTo(0,500), '*');

// var innerDoc = iframe.contentDocument || iframe.contentWindow.document;


    })

  }

  // getSaveData(){

  //   this.subscription = this.data_prepare.getSaveData().subscribe(res => {

  //    ////console.log("getSaveData",res);
  //    this.jsonData = res;
  //   })
  //  };

}
