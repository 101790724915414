import { Injectable } from '@angular/core';
import { Constants } from '../classes/constant';
import { FormField } from '../classes/form-field';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { AmBuilderService } from '../../am-builder.service';

@Injectable({
  providedIn: 'root'
})
export class FormDataPrepareService {

  saveData: any = new Subject<any>();

  changeConfig: any = new Subject<any>();

  formShow: any = new Subject<any>();

  refreshData: any = new Subject<any>();
  sectionData: any = new Subject<any>();

  themeSettingData: any = new Subject<any>();


  anaylticsObj : any = new Subject<any>();
  cssSettingObj : any = new Subject<any>();

  pageChange : any = new Subject<any>();

  isFieldEdited : any = new Subject<any>();

  themeVersion : any = new Subject<any>();

  headerCustomeComponentData: any = new Subject<any>();





  fonts = ['Alegreya', 'Alegreya Sans', 'Amiri', 'Anonymous Pro', 'Arapey', 'Archivo', 'Archivo Narrow', 'Arimo', 'Arvo', 'Asap', 'Assistant', 'Asul',
    'Bitter', 'Cabin', 'Cardo', 'Catamaran', 'Chivo', 'Domine', 'Dosis', 'Daytona' ,'DM Sans' , 'Electra' ,'Fira Sans', 'Fjalla One', 'Futura' ,'Glegoo','Harmonia Sans' ,'IBM Plex Sans',
    'Inconsolata', 'ITC Stone Informal' ,'Josefin Sans', 'Josefin Slab', 'Kalam', 'Karla', 'Kreon', 'Lato', 'Libre Baskerville', 'Libre Franklin', 'Lobster', 'Lobster Two', 'Lora', 'Maven Pro', 'Megrim',
    'Merriweather Sans', 'Montserrat', 'Mouse Memoirs', 'Neuton', 'Nobile', 'Noticia Text', 'Noto Serif', 'Nunito', 'Nunito Sans', 'Old Standard TT', 'Open Sans', 'Oswald', 'Ovo', 'Oxygen',
    'PT Mono', 'PT Sans', 'PT Sans Narrow', 'PT Serif', 'Pacifico', 'Playfair Display', 'Poppins', 'Prata', 'Prompt', 'Quantico', 'Quattrocento', 'Quattrocento Sans', 'Questrial',
    'Quicksand', 'Rajdhani', 'Raleway', 'Righteous', 'Roboto', 'Roboto Condensed', 'Roboto Mono', 'Roboto Slab', 'Rubik', 'Shadows Into Light', 'Source Code Pro',
    'Tenor Sans', 'Times New Roman' ,'Tinos', 'Titillium Web', 'Ubuntu', 'Unica One', 'Unna', 'Varela', 'Varela Round', 'Volkhov', 'Vollkorn', 'Work Sans','DMSans',
  ];
  cdn_link = '';

  menuOptions : any = [
    {
      label : 'Main Menu',
      value : 'main-menu'
    },
    // {
    //   label : 'Footer Menu',
    //   value : 'footer'
    // }
  ];

  scrollToSection: any = new Subject<any>();

  version : any;

  constructor(
    public amBuilderService : AmBuilderService
  ) {
    this.cdn_link = localStorage.getItem('cdn_link');

    this.version = localStorage.getItem('dawnVersion')


  }

  prepareJson(settingData, schema) {

    return new Promise((resolve, reject) => {

      // console.log(this.amBuilderService.editorDetails.menus);
      let arr = [];
      for(let i=0; i < this.amBuilderService.editorDetails.menus.length; i++){

        let obj = {

          label : this.amBuilderService.editorDetails.menus[i].title,
          value : this.amBuilderService.editorDetails.menus[i].handle
        }

        arr.push(obj);
      }
      this.menuOptions = arr;
      // console.log("this.menuOptions;",this.menuOptions)

      if(settingData.settings){
      let keys = Object.keys(settingData.settings);
      let labelData;
      if(this.version == '2'){
        labelData = Constants.labelDataV7
      } else if(this.version == '3'){

        labelData = Constants.labelDataV10

      } else if(this.version == 'ef420'){
        labelData = Constants.labelDataEf420
      }
      let arr = [];
      for (let s = 0; s < schema.length; s++) {

        let obj = {

          label: '',
          required: false,
          type: schema[s].type,
          // value : settingData.settings[keys[i]],
          validations: [],
          // key : keys[s],
          // options : [],
          // sectionName : '',
          // info : '',
          min : schema[s].min,
          max : schema[s].max,
          step : schema[s].step,
          unit : schema[s].unit,

        }



        if (schema[s]['label']) {

          if (schema[s].label.indexOf('t:') > -1){

          let label = schema[s].label.replace("t:", "");
          // //console.log("label",label);
          obj['label'] = this.getValue(labelData, label)
          } else {

            obj['label'] = schema[s]['label']
          }
        }

        if(schema[s]['unit']) {

          let label = schema[s].unit.replace("t:", "");
          // //console.log("Unit",schema[s],label);
          if(label != 'px'){
          obj['unit'] = this.getValue(labelData, label);
        } else {
          obj['unit'] = 'px';
        }
          // //console.log("Value",obj['content']);
        }

        if(schema[s]['content']) {

          let label = schema[s].content.replace("t:", "");
          // //console.log("Lable",label,labelData);
          obj['content'] = this.getValue(labelData, label);
          // //console.log("Value",obj['content']);
        }

        if(schema[s]['info']) {

          let info = schema[s].info.replace("t:", "")
          obj['info'] = this.getValue(labelData, info);
        }
        if(schema[s]['id']){
          obj['key'] = schema[s]['id'];

        }

        if(schema[s]['type'] == 'image_picker'){
          // console.log("i m hereee",settingData.settings[obj['key']]);
          if(settingData.settings[obj['key']]){
          if (settingData.settings[obj['key']].includes("shopify:")) {
            // console.log("this.cdn_link",this.cdn_link);
            settingData.settings[obj['key']] = settingData.settings[obj['key']].replace("shopify://shop_images/", this.cdn_link);
            //console.log(settingData.settings[obj['key']])
          }
      } else {
        settingData.settings[obj['key']] = ""
      }
        }
        if(schema[s]['id']){

          obj['value'] = settingData.settings[obj['key']];
          obj['type'] = schema[s]['type'];
        }


        if(schema[s]['options']){

          obj['options'] = schema[s]['options'];

          let length = obj['options'].length;
          for (let j = 0; j < length; j++) {

            // //console.log(obj['options'][j].label.indexOf('sections') > -1)
            if (obj['options'][j].label.indexOf('sections') > -1){

            let label = obj['options'][j].label.replace("t:", "");
            // //console.log("option label", label);
            obj['options'][j].label = this.getValue(labelData, label);
          }

          }

        }
        if(obj['type'] == 'link_list'){
          obj['options'] = this.menuOptions;
        }
        if(obj['type'] == 'color_scheme') {
          obj['options'] = this.amBuilderService.colorSchemes;
        }
        console.log("options",obj)
        arr.push(obj);
    }

      resolve(arr)
    } else {
      resolve([])

    }

    })

  }

  getValue(obj, path) {
    if (!path) return obj;
    const properties = path.split('.');

    let key = properties.shift();

    if( obj[key]  ) {
      return this.getValue(obj[key], properties.join('.'))
    } else {
      this.getValue({},undefined);
    }
  }

  setData(value){
    this.saveData.next(value);
  }

  getSaveData(): Observable<any> {
    return this.saveData.asObservable();
  }

  changeConfigData(value){
    this.changeConfig.next(value);
  }

  getChangedData(): Observable<any> {
    return this.changeConfig.asObservable();
  }

  setFormFlag(value){
    this.formShow.next(value);
  }

  getSaveFlag(): Observable<any> {
    return this.formShow.asObservable();
  }

  setRefreshFlag(value){
    this.refreshData.next(value);
  }

  getRefreshFlag(): Observable<any> {
    return this.refreshData.asObservable();
  }

  prepareSettingJson(settingData, schema) {

    return new Promise((resolve, reject) => {

      // console.log("prepareSettingJson",settingData)
      let keys = Object.keys(settingData.settings);
      let labelData;
      if(this.version == '2'){
        labelData = Constants.globeSettingLabelV7
      } else if(this.version == '3'){

        labelData = Constants.globalSettingLabelsV10

      } else if(this.version == 'ef420'){
        labelData = Constants.globeSettingLabelEf420
      }
      let arr = [];
      for (let s = 0; s < schema.length; s++) {

        let obj = {

          label: '',
          required: false,
          type: schema[s].type,
          value : settingData.settings[keys[s]],
          validations: [],
          // key : keys[s],
          // options : [],
          // sectionName : '',
          // info : '',
          min : schema[s].min,
          max : schema[s].max,
          step : schema[s].step,
          unit : schema[s].unit
        }

        //console.log(schema[s]['type'])
        if(schema[s]['type'] == 'font_picker'){

          obj['options'] = this.fonts;

        }
        if (schema[s]['label']) {

          if (schema[s].label.indexOf('t:') > -1){

          let label = schema[s].label.replace("t:", "");
          // //console.log("label",label);
          obj['label'] = this.getValue(labelData, label)
          } else {

            obj['label'] = schema[s]['label']
          }
        }

        if(schema[s]['content']) {

          if(schema[s].content.includes('t:')){
          let label = schema[s].content.replace("t:", "");
          // //console.log("Lable",label,labelData);
          obj['content'] = this.getValue(labelData, label);
        } else {
          obj['content'] = schema[s].content;
        }
          console.log("Value",obj['content']);
        }

        if(schema[s]['info']) {

          let info = schema[s].info.replace("t:", "")
          obj['info'] = this.getValue(labelData, info);
        }

        if(schema[s]['id']){

          obj['key'] = schema[s]['id'];
          obj['value'] = settingData.settings[obj['key']];
          obj['type'] = schema[s]['type'];
        }
        if(obj['type'] == 'font_picker'){

          let tmp = obj['value'].split('_')[0];
          obj['value'] = tmp.charAt(0).toUpperCase() + tmp.slice(1)

          //console.log(obj['value'])

        }

        if(schema[s]['options']){

          obj['options'] = schema[s]['options'];

          let length = obj['options'].length;
          for (let j = 0; j < length; j++) {

            // //console.log(obj['options'][j].label.indexOf('sections') > -1)
            if (obj['options'][j].label.indexOf('sections') > -1){

            let label = obj['options'][j].label.replace("t:", "");
            // //console.log("option label", label);
            obj['options'][j].label = this.getValue(labelData, label);
          } else {

            if (obj['options'][j].label.indexOf('settings_schema') > -1){
              let label = obj['options'][j].label.replace("t:", "");
              // //console.log("option label", label);
              obj['options'][j].label = this.getValue(labelData, label);
            }
          }

          }

        }
        if(schema[s]['type'] == 'image_picker'){
          // console.log("i m hereee",settingData.settings[obj['key']]);
          if(settingData.settings[obj['key']]){
          if (settingData.settings[obj['key']].includes("shopify:")) {
            // console.log("this.cdn_link",this.cdn_link);
            settingData.settings[obj['key']] = settingData.settings[obj['key']].replace("shopify://shop_images/", this.cdn_link);
            // console.log(settingData.settings[obj['key']])
            obj['value'] = settingData.settings[obj['key']];
            //console.log(settingData.settings[obj['key']])
          }
      } else {
        settingData.settings[obj['key']] = ""
      }
        }
        arr.push(obj);
      }

      //console.log("prepared setting array",arr);
      resolve(arr)

    })

  }

  setThemeSettings(value){
    //console.log("setThemeSettings/........",value)
    this.themeSettingData.next(value);
  }
  getThemeSettings(): Observable<any> {
    return this.themeSettingData.asObservable();
  }

  setheaderCustomeComponent(value){

    this.headerCustomeComponentData.next(value);
  }
  getheaderCustomeComponent(): Observable<any> {
    return this.headerCustomeComponentData.asObservable();
  }

  setSectionData(value){

    this.sectionData.next(value);

  }
  getSectionData(): Observable<any> {
    return this.sectionData.asObservable();
  }

  setAnalyticsSettings(value){
    // //console.log("setThemeSettings",value)
    this.anaylticsObj.next(value);
  }
  getAnalyticsSettings(): Observable<any> {
    return this.anaylticsObj.asObservable();
  }
  setScrollSection(value){
    // //console.log("setThemeSettings",value)
    this.scrollToSection.next(value);
  }
  getScrollSection(): Observable<any> {
    return this.scrollToSection.asObservable();
  }

  setCssSettings(value){
    // //console.log("setThemeSettings",value)
    this.cssSettingObj.next(value);
  }
  getCssSettings(): Observable<any> {
    return this.cssSettingObj.asObservable();
  }
  setPageChangeEvent(value){
    // //console.log("setThemeSettings",value)
    this.pageChange.next(value);
  }
  getPageChangeEvent(): Observable<any> {
    return this.pageChange.asObservable();
  }
  setFieldEditedEvent(value){
    // //console.log("setThemeSettings",value)
    this.isFieldEdited.next(value);
  }
  getFieldEditedEvent(): Observable<any> {
    return this.isFieldEdited.asObservable();
  }

  setThemeVersion(value){
    //console.log("setFormFlag",value);
    this.themeVersion.next(value);
  }

  getThemeVersion(): Observable<any> {
    return this.themeVersion.asObservable();
  }



}
